import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import {
  LOYALTY_SHARE_ROUTE, LOYALTY_ROUTE,
  LOYALTY_STAMP_ROUTE, LOYALTY_REDEEM_ROUTE,
} from 'constants/navigation';
import {
  SHARE, RETRY,
  LOYALTY_REDEEM, LOYALTY_STAMP,
} from 'constants/font-awesome';

import useStyles from '../../styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/two'));
const Page = loadable(() => import('components/page'));
const LazyUpdateLoyaltyRedeem = loadable(() => import('views/loyalty-card/loyalty/update/update-redeem'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedRedeemeLoyaltyPage = ({ passId = '' }) => {
  const styles = useStyles();

  return (
      <Page
        title="Redeem reward"
        description="Redeem customer rewards and update Loyalty card.">
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          Redeem Loyalty Rewards
        </Typography>
        <div className={styles.content}>
          <LazyUpdateLoyaltyRedeem passId={passId}/>
        </div>
        <LazyOverrideFooter
          navItems={[
            { route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: RETRY, message: 'Search Again' },
            { route: `${LOYALTY_STAMP_ROUTE}?passId=${passId}`, icon: LOYALTY_STAMP, message: 'Stamp' },
            { route: `${LOYALTY_REDEEM_ROUTE}?passId=${passId}`, icon: LOYALTY_REDEEM, message: 'Redeem' },
            {
              route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`,
              icon: SHARE,
              message: 'Share',
            },
          ]}
          currentItem="Redeem"
          />
      </Page>
  );
};

SuspendedRedeemeLoyaltyPage.propTypes = { passId: string };

export default SuspendedRedeemeLoyaltyPage;
