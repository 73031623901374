import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  defaultSkeleton: {
    display: 'table',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});
