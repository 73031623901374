import theme from 'theme';

export const baseStyles = {
  heading: {
    marginTop: `${theme.spacing(4)}px !important`,
  },
  headingXs: {
    marginTop: `${theme.spacing(4)}px !important`,
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  headingMessage: {
    marginTop: `${theme.spacing(1)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
  },
  fourTopBotMargin: {
    marginTop: `${theme.spacing(4)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
  },
};
